import Vue from 'vue'

document.addEventListener('DOMContentLoaded', () => {
  var app_calendar = new Vue({
    el: '#app',
    data() {
      return {
        email: null,
        password: null,
      }
    },
    methods: {
      submit_click() {
        if ( navigator.userAgent.match(/spapp/) ) {
          localStorage.setItem("sigin_in_email", this.email)
          localStorage.setItem("sigin_in_password", this.password)
        }
      },
    },
    mounted() {
      this.email = localStorage.getItem("sigin_in_email")
      this.password = localStorage.getItem("sigin_in_password")
    },
  })
})
